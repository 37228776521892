// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PackageDetailsFieldsCoachingFocusFragment = {
  __typename?: 'CoachingFocus';
  id: string;
  name: string;
};

export type PackageDetailsFieldsUserFragment = {
  __typename?: 'User';
  id: string;
  coach?: {
    __typename?: 'Coach';
    id: string;
    hourlyRate?: number | null;
  } | null;
};

export type PackageDetailsFieldsCategoryFragment = {
  __typename?: 'Category';
  id: string;
  coachingFocusList?: Array<{
    __typename?: 'CoachingFocus';
    id: string;
    name: string;
  }> | null;
  comprehensiveFocusList?: Array<{
    __typename?: 'CoachingFocus';
    id: string;
    name: string;
  }> | null;
};

export type PackageDetailsFieldsGetCategoryQueryVariables = Types.Exact<{
  categoryId: Types.Scalars['CategoryUrn']['input'];
}>;

export type PackageDetailsFieldsGetCategoryQuery = {
  __typename?: 'Query';
  category: {
    __typename?: 'Category';
    id: string;
    coachingFocusList?: Array<{
      __typename?: 'CoachingFocus';
      id: string;
      name: string;
    }> | null;
    comprehensiveFocusList?: Array<{
      __typename?: 'CoachingFocus';
      id: string;
      name: string;
    }> | null;
  };
};

export const PackageDetailsFieldsUserFragmentDoc = gql`
  fragment PackageDetailsFields_User on User {
    id
    coach {
      id
      hourlyRate
    }
  }
`;
export const PackageDetailsFieldsCoachingFocusFragmentDoc = gql`
  fragment PackageDetailsFields_CoachingFocus on CoachingFocus {
    id
    name
  }
`;
export const PackageDetailsFieldsCategoryFragmentDoc = gql`
  fragment PackageDetailsFields_Category on Category {
    id
    coachingFocusList {
      ...PackageDetailsFields_CoachingFocus
    }
    comprehensiveFocusList {
      ...PackageDetailsFields_CoachingFocus
    }
  }
  ${PackageDetailsFieldsCoachingFocusFragmentDoc}
`;
export const PackageDetailsFieldsGetCategoryDocument = gql`
  query PackageDetailsFieldsGetCategory($categoryId: CategoryUrn!) {
    category(id: $categoryId) {
      ...PackageDetailsFields_Category
    }
  }
  ${PackageDetailsFieldsCategoryFragmentDoc}
`;

/**
 * __usePackageDetailsFieldsGetCategoryQuery__
 *
 * To run a query within a React component, call `usePackageDetailsFieldsGetCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackageDetailsFieldsGetCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackageDetailsFieldsGetCategoryQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function usePackageDetailsFieldsGetCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    PackageDetailsFieldsGetCategoryQuery,
    PackageDetailsFieldsGetCategoryQueryVariables
  > &
    (
      | {
          variables: PackageDetailsFieldsGetCategoryQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PackageDetailsFieldsGetCategoryQuery,
    PackageDetailsFieldsGetCategoryQueryVariables
  >(PackageDetailsFieldsGetCategoryDocument, options);
}
export function usePackageDetailsFieldsGetCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PackageDetailsFieldsGetCategoryQuery,
    PackageDetailsFieldsGetCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PackageDetailsFieldsGetCategoryQuery,
    PackageDetailsFieldsGetCategoryQueryVariables
  >(PackageDetailsFieldsGetCategoryDocument, options);
}
export function usePackageDetailsFieldsGetCategorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PackageDetailsFieldsGetCategoryQuery,
    PackageDetailsFieldsGetCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PackageDetailsFieldsGetCategoryQuery,
    PackageDetailsFieldsGetCategoryQueryVariables
  >(PackageDetailsFieldsGetCategoryDocument, options);
}
export type PackageDetailsFieldsGetCategoryQueryHookResult = ReturnType<
  typeof usePackageDetailsFieldsGetCategoryQuery
>;
export type PackageDetailsFieldsGetCategoryLazyQueryHookResult = ReturnType<
  typeof usePackageDetailsFieldsGetCategoryLazyQuery
>;
export type PackageDetailsFieldsGetCategorySuspenseQueryHookResult = ReturnType<
  typeof usePackageDetailsFieldsGetCategorySuspenseQuery
>;
export type PackageDetailsFieldsGetCategoryQueryResult = Apollo.QueryResult<
  PackageDetailsFieldsGetCategoryQuery,
  PackageDetailsFieldsGetCategoryQueryVariables
>;
