// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetDesiredPayoutCoachFragment = {
  __typename?: 'Coach';
  id: string;
  hourlyRate?: number | null;
  desiredPayout?: number | null;
  openToLowerThanDesiredPayout?: boolean | null;
};

export type SetDesiredPayoutUpdateCoachPayoutPreferenceMutationVariables =
  Types.Exact<{
    openToLowerThanDesiredPayout?: Types.InputMaybe<
      Types.Scalars['Boolean']['input']
    >;
    desiredPayout?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  }>;

export type SetDesiredPayoutUpdateCoachPayoutPreferenceMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: string;
    coach?: {
      __typename?: 'Coach';
      id: string;
      hourlyRate?: number | null;
      desiredPayout?: number | null;
      openToLowerThanDesiredPayout?: boolean | null;
    } | null;
  };
};

export const SetDesiredPayoutCoachFragmentDoc = gql`
  fragment SetDesiredPayout_Coach on Coach {
    id
    hourlyRate
    desiredPayout
    openToLowerThanDesiredPayout
  }
`;
export const SetDesiredPayoutUpdateCoachPayoutPreferenceDocument = gql`
  mutation SetDesiredPayout_UpdateCoachPayoutPreference(
    $openToLowerThanDesiredPayout: Boolean
    $desiredPayout: Int
  ) {
    updateUser(
      coach: {
        openToLowerThanDesiredPayout: $openToLowerThanDesiredPayout
        desiredPayout: $desiredPayout
      }
    ) {
      id
      coach {
        ...SetDesiredPayout_Coach
      }
    }
  }
  ${SetDesiredPayoutCoachFragmentDoc}
`;
export type SetDesiredPayoutUpdateCoachPayoutPreferenceMutationFn =
  Apollo.MutationFunction<
    SetDesiredPayoutUpdateCoachPayoutPreferenceMutation,
    SetDesiredPayoutUpdateCoachPayoutPreferenceMutationVariables
  >;

/**
 * __useSetDesiredPayoutUpdateCoachPayoutPreferenceMutation__
 *
 * To run a mutation, you first call `useSetDesiredPayoutUpdateCoachPayoutPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDesiredPayoutUpdateCoachPayoutPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDesiredPayoutUpdateCoachPayoutPreferenceMutation, { data, loading, error }] = useSetDesiredPayoutUpdateCoachPayoutPreferenceMutation({
 *   variables: {
 *      openToLowerThanDesiredPayout: // value for 'openToLowerThanDesiredPayout'
 *      desiredPayout: // value for 'desiredPayout'
 *   },
 * });
 */
export function useSetDesiredPayoutUpdateCoachPayoutPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetDesiredPayoutUpdateCoachPayoutPreferenceMutation,
    SetDesiredPayoutUpdateCoachPayoutPreferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetDesiredPayoutUpdateCoachPayoutPreferenceMutation,
    SetDesiredPayoutUpdateCoachPayoutPreferenceMutationVariables
  >(SetDesiredPayoutUpdateCoachPayoutPreferenceDocument, options);
}
export type SetDesiredPayoutUpdateCoachPayoutPreferenceMutationHookResult =
  ReturnType<typeof useSetDesiredPayoutUpdateCoachPayoutPreferenceMutation>;
export type SetDesiredPayoutUpdateCoachPayoutPreferenceMutationResult =
  Apollo.MutationResult<SetDesiredPayoutUpdateCoachPayoutPreferenceMutation>;
export type SetDesiredPayoutUpdateCoachPayoutPreferenceMutationOptions =
  Apollo.BaseMutationOptions<
    SetDesiredPayoutUpdateCoachPayoutPreferenceMutation,
    SetDesiredPayoutUpdateCoachPayoutPreferenceMutationVariables
  >;
