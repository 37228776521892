// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import { SetDesiredPayoutCoachFragmentDoc } from '../../__generated-gql-types__/SetDesiredPayout.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CoachOnboardingUpdateOptInTrialSessionsMutationVariables =
  Types.Exact<{
    optInTrialSessions: Types.Scalars['Boolean']['input'];
  }>;

export type CoachOnboardingUpdateOptInTrialSessionsMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: string;
    coach?: {
      __typename?: 'Coach';
      id: string;
      optInTrialSessions?: boolean | null;
    } | null;
  };
};

export type CoachOnboardingCoachFragment = {
  __typename?: 'Coach';
  id: string;
  tier: Types.SkuTier;
  desiredPayout?: number | null;
  optInTrialSessions?: boolean | null;
  hourlyRate?: number | null;
  openToLowerThanDesiredPayout?: boolean | null;
};

export type CoachOnboardingUserQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type CoachOnboardingUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    coach?: {
      __typename?: 'Coach';
      id: string;
      tier: Types.SkuTier;
      desiredPayout?: number | null;
      optInTrialSessions?: boolean | null;
      hourlyRate?: number | null;
      openToLowerThanDesiredPayout?: boolean | null;
    } | null;
  } | null;
};

export type CoachOnboardingOneSessionMonthlySkuQueryVariables = Types.Exact<{
  tier: Types.SkuTier;
}>;

export type CoachOnboardingOneSessionMonthlySkuQuery = {
  __typename?: 'Query';
  findSku: { __typename?: 'Sku'; id: string; unitPayout: number };
};

export const CoachOnboardingCoachFragmentDoc = gql`
  fragment CoachOnboarding_Coach on Coach {
    id
    tier
    desiredPayout
    optInTrialSessions
    ...SetDesiredPayout_Coach
  }
  ${SetDesiredPayoutCoachFragmentDoc}
`;
export const CoachOnboardingUpdateOptInTrialSessionsDocument = gql`
  mutation CoachOnboarding_UpdateOptInTrialSessions(
    $optInTrialSessions: Boolean!
  ) {
    updateUser(coach: { optInTrialSessions: $optInTrialSessions }) {
      id
      coach {
        id
        optInTrialSessions
      }
    }
  }
`;
export type CoachOnboardingUpdateOptInTrialSessionsMutationFn =
  Apollo.MutationFunction<
    CoachOnboardingUpdateOptInTrialSessionsMutation,
    CoachOnboardingUpdateOptInTrialSessionsMutationVariables
  >;

/**
 * __useCoachOnboardingUpdateOptInTrialSessionsMutation__
 *
 * To run a mutation, you first call `useCoachOnboardingUpdateOptInTrialSessionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoachOnboardingUpdateOptInTrialSessionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coachOnboardingUpdateOptInTrialSessionsMutation, { data, loading, error }] = useCoachOnboardingUpdateOptInTrialSessionsMutation({
 *   variables: {
 *      optInTrialSessions: // value for 'optInTrialSessions'
 *   },
 * });
 */
export function useCoachOnboardingUpdateOptInTrialSessionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CoachOnboardingUpdateOptInTrialSessionsMutation,
    CoachOnboardingUpdateOptInTrialSessionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CoachOnboardingUpdateOptInTrialSessionsMutation,
    CoachOnboardingUpdateOptInTrialSessionsMutationVariables
  >(CoachOnboardingUpdateOptInTrialSessionsDocument, options);
}
export type CoachOnboardingUpdateOptInTrialSessionsMutationHookResult =
  ReturnType<typeof useCoachOnboardingUpdateOptInTrialSessionsMutation>;
export type CoachOnboardingUpdateOptInTrialSessionsMutationResult =
  Apollo.MutationResult<CoachOnboardingUpdateOptInTrialSessionsMutation>;
export type CoachOnboardingUpdateOptInTrialSessionsMutationOptions =
  Apollo.BaseMutationOptions<
    CoachOnboardingUpdateOptInTrialSessionsMutation,
    CoachOnboardingUpdateOptInTrialSessionsMutationVariables
  >;
export const CoachOnboardingUserDocument = gql`
  query CoachOnboarding_User {
    user {
      id
      coach {
        ...CoachOnboarding_Coach
      }
    }
  }
  ${CoachOnboardingCoachFragmentDoc}
`;

/**
 * __useCoachOnboardingUserQuery__
 *
 * To run a query within a React component, call `useCoachOnboardingUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachOnboardingUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachOnboardingUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoachOnboardingUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoachOnboardingUserQuery,
    CoachOnboardingUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CoachOnboardingUserQuery,
    CoachOnboardingUserQueryVariables
  >(CoachOnboardingUserDocument, options);
}
export function useCoachOnboardingUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachOnboardingUserQuery,
    CoachOnboardingUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CoachOnboardingUserQuery,
    CoachOnboardingUserQueryVariables
  >(CoachOnboardingUserDocument, options);
}
export function useCoachOnboardingUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CoachOnboardingUserQuery,
    CoachOnboardingUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CoachOnboardingUserQuery,
    CoachOnboardingUserQueryVariables
  >(CoachOnboardingUserDocument, options);
}
export type CoachOnboardingUserQueryHookResult = ReturnType<
  typeof useCoachOnboardingUserQuery
>;
export type CoachOnboardingUserLazyQueryHookResult = ReturnType<
  typeof useCoachOnboardingUserLazyQuery
>;
export type CoachOnboardingUserSuspenseQueryHookResult = ReturnType<
  typeof useCoachOnboardingUserSuspenseQuery
>;
export type CoachOnboardingUserQueryResult = Apollo.QueryResult<
  CoachOnboardingUserQuery,
  CoachOnboardingUserQueryVariables
>;
export const CoachOnboardingOneSessionMonthlySkuDocument = gql`
  query CoachOnboarding_OneSessionMonthlySku($tier: SkuTier!) {
    findSku(tier: $tier, period: MONTHLY, numberOfSessions: 1) {
      id
      unitPayout
    }
  }
`;

/**
 * __useCoachOnboardingOneSessionMonthlySkuQuery__
 *
 * To run a query within a React component, call `useCoachOnboardingOneSessionMonthlySkuQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachOnboardingOneSessionMonthlySkuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachOnboardingOneSessionMonthlySkuQuery({
 *   variables: {
 *      tier: // value for 'tier'
 *   },
 * });
 */
export function useCoachOnboardingOneSessionMonthlySkuQuery(
  baseOptions: Apollo.QueryHookOptions<
    CoachOnboardingOneSessionMonthlySkuQuery,
    CoachOnboardingOneSessionMonthlySkuQueryVariables
  > &
    (
      | {
          variables: CoachOnboardingOneSessionMonthlySkuQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CoachOnboardingOneSessionMonthlySkuQuery,
    CoachOnboardingOneSessionMonthlySkuQueryVariables
  >(CoachOnboardingOneSessionMonthlySkuDocument, options);
}
export function useCoachOnboardingOneSessionMonthlySkuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachOnboardingOneSessionMonthlySkuQuery,
    CoachOnboardingOneSessionMonthlySkuQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CoachOnboardingOneSessionMonthlySkuQuery,
    CoachOnboardingOneSessionMonthlySkuQueryVariables
  >(CoachOnboardingOneSessionMonthlySkuDocument, options);
}
export function useCoachOnboardingOneSessionMonthlySkuSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CoachOnboardingOneSessionMonthlySkuQuery,
    CoachOnboardingOneSessionMonthlySkuQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CoachOnboardingOneSessionMonthlySkuQuery,
    CoachOnboardingOneSessionMonthlySkuQueryVariables
  >(CoachOnboardingOneSessionMonthlySkuDocument, options);
}
export type CoachOnboardingOneSessionMonthlySkuQueryHookResult = ReturnType<
  typeof useCoachOnboardingOneSessionMonthlySkuQuery
>;
export type CoachOnboardingOneSessionMonthlySkuLazyQueryHookResult = ReturnType<
  typeof useCoachOnboardingOneSessionMonthlySkuLazyQuery
>;
export type CoachOnboardingOneSessionMonthlySkuSuspenseQueryHookResult =
  ReturnType<typeof useCoachOnboardingOneSessionMonthlySkuSuspenseQuery>;
export type CoachOnboardingOneSessionMonthlySkuQueryResult = Apollo.QueryResult<
  CoachOnboardingOneSessionMonthlySkuQuery,
  CoachOnboardingOneSessionMonthlySkuQueryVariables
>;
