// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OpportunityContextMatchedCoachFragment = {
  __typename?: 'MatchedCoach';
  id: string;
};

export type OpportunityContextMatchedCoachesForCoachQueryVariables =
  Types.Exact<{ [key: string]: never }>;

export type OpportunityContextMatchedCoachesForCoachQuery = {
  __typename?: 'Query';
  matchedCoachesForCoach: {
    __typename?: 'MatchedCoachBatchResponse';
    results: Array<{ __typename?: 'MatchedCoach'; id: string }>;
  };
};

export const OpportunityContextMatchedCoachFragmentDoc = gql`
  fragment OpportunityContext_MatchedCoach on MatchedCoach {
    id
  }
`;
export const OpportunityContextMatchedCoachesForCoachDocument = gql`
  query OpportunityContext_MatchedCoachesForCoach {
    matchedCoachesForCoach(status: REQUESTED) {
      results {
        ...OpportunityContext_MatchedCoach
      }
    }
  }
  ${OpportunityContextMatchedCoachFragmentDoc}
`;

/**
 * __useOpportunityContextMatchedCoachesForCoachQuery__
 *
 * To run a query within a React component, call `useOpportunityContextMatchedCoachesForCoachQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpportunityContextMatchedCoachesForCoachQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpportunityContextMatchedCoachesForCoachQuery({
 *   variables: {
 *   },
 * });
 */
export function useOpportunityContextMatchedCoachesForCoachQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OpportunityContextMatchedCoachesForCoachQuery,
    OpportunityContextMatchedCoachesForCoachQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OpportunityContextMatchedCoachesForCoachQuery,
    OpportunityContextMatchedCoachesForCoachQueryVariables
  >(OpportunityContextMatchedCoachesForCoachDocument, options);
}
export function useOpportunityContextMatchedCoachesForCoachLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OpportunityContextMatchedCoachesForCoachQuery,
    OpportunityContextMatchedCoachesForCoachQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OpportunityContextMatchedCoachesForCoachQuery,
    OpportunityContextMatchedCoachesForCoachQueryVariables
  >(OpportunityContextMatchedCoachesForCoachDocument, options);
}
export function useOpportunityContextMatchedCoachesForCoachSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OpportunityContextMatchedCoachesForCoachQuery,
    OpportunityContextMatchedCoachesForCoachQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OpportunityContextMatchedCoachesForCoachQuery,
    OpportunityContextMatchedCoachesForCoachQueryVariables
  >(OpportunityContextMatchedCoachesForCoachDocument, options);
}
export type OpportunityContextMatchedCoachesForCoachQueryHookResult =
  ReturnType<typeof useOpportunityContextMatchedCoachesForCoachQuery>;
export type OpportunityContextMatchedCoachesForCoachLazyQueryHookResult =
  ReturnType<typeof useOpportunityContextMatchedCoachesForCoachLazyQuery>;
export type OpportunityContextMatchedCoachesForCoachSuspenseQueryHookResult =
  ReturnType<typeof useOpportunityContextMatchedCoachesForCoachSuspenseQuery>;
export type OpportunityContextMatchedCoachesForCoachQueryResult =
  Apollo.QueryResult<
    OpportunityContextMatchedCoachesForCoachQuery,
    OpportunityContextMatchedCoachesForCoachQueryVariables
  >;
