import { useApolloClient } from '@apollo/client';
import {
  createContext,
  type FC,
  type PropsWithChildren,
  useCallback,
  useContext,
} from 'react';

import {
  OpportunityContextMatchedCoachesForCoachDocument,
  type OpportunityContextMatchedCoachesForCoachQuery,
  type OpportunityContextMatchedCoachFragment,
  useOpportunityContextMatchedCoachesForCoachQuery,
} from './__generated-gql-types__/OpportunityContext.generated';

interface OpportunityContext {
  opportunities: OpportunityContextMatchedCoachFragment[];
  addOpportunity: (
    matchedCoach: OpportunityContextMatchedCoachFragment,
  ) => void;
  removeOpportunity: (matchedCoachUrn: string) => void;
}

const MISSING_OPPORTUNITY_COUNT_CONTEXT_PROVIDER =
  'You forgot to wrap your app in <OpportunityCountProvider>';

export const OpportunityContext = createContext<OpportunityContext>({
  get opportunities(): never {
    throw new Error(MISSING_OPPORTUNITY_COUNT_CONTEXT_PROVIDER);
  },
  get addOpportunity(): never {
    throw new Error(MISSING_OPPORTUNITY_COUNT_CONTEXT_PROVIDER);
  },
  get removeOpportunity(): never {
    throw new Error(MISSING_OPPORTUNITY_COUNT_CONTEXT_PROVIDER);
  },
});

export const useOpportunities: () => OpportunityContext = () =>
  useContext<OpportunityContext>(OpportunityContext);

export const OpportunityProvider: FC<PropsWithChildren> = ({ children }) => {
  const apolloClient = useApolloClient();
  const { data } = useOpportunityContextMatchedCoachesForCoachQuery();

  const addOpportunity = useCallback(
    (matchedCoach: OpportunityContextMatchedCoachFragment) => {
      apolloClient.cache.updateQuery<OpportunityContextMatchedCoachesForCoachQuery>(
        {
          query: OpportunityContextMatchedCoachesForCoachDocument,
        },
        (data) => ({
          matchedCoachesForCoach: {
            results: [
              matchedCoach,
              // Filter prevents duplicates in case of bad state / update actions
              ...(data?.matchedCoachesForCoach?.results?.filter(
                (mc) => mc.id !== matchedCoach.id,
              ) ?? []),
            ],
          },
        }),
      );
    },
    [apolloClient.cache],
  );

  const removeOpportunity = useCallback(
    (matchedCoachUrn: string) => {
      apolloClient.cache.updateQuery<OpportunityContextMatchedCoachesForCoachQuery>(
        {
          query: OpportunityContextMatchedCoachesForCoachDocument,
        },
        (data) => ({
          matchedCoachesForCoach: {
            results:
              data?.matchedCoachesForCoach?.results.filter(
                (matchedCoach) => matchedCoach.id !== matchedCoachUrn,
              ) ?? [],
          },
        }),
      );
    },
    [apolloClient.cache],
  );

  return (
    <OpportunityContext.Provider
      value={{
        opportunities: data?.matchedCoachesForCoach?.results ?? [],
        addOpportunity,
        removeOpportunity,
      }}
    >
      {children}
    </OpportunityContext.Provider>
  );
};
