import type { FetchResult } from '@apollo/client';
import type { GraphQLErrors } from '@apollo/client/errors';

export const getFirstErrorMessage = (
  errors: FetchResult['errors'],
): string | undefined => {
  if (errors?.length) {
    return (errors[0].extensions?.message ??
      errors[0].message) as Optional<string>;
  }
};

export const getFirstErrorCode = (
  errors: FetchResult['errors'],
): string | undefined => {
  if (errors?.length) {
    return errors[0].extensions?.errorCode as Optional<string>;
  }
};

export const formatErrors = (errors: GraphQLErrors): string => {
  return errors.map((error) => error.message).join(', ');
};
