import dynamic from 'next/dynamic';
import React, { type ComponentType, type HTMLProps } from 'react';

const Achievements = dynamic(() => import('../assets/svgs/achievements.svg'));
const Add = dynamic(() => import('../assets/svgs/add-plus.svg'));
const Alert = dynamic(() => import('../assets/svgs/alert.svg'));
const Applicants = dynamic(() => import('../assets/svgs/applicants.svg'));
const Application = dynamic(() => import('../assets/svgs/application.svg'));
const ApplicationEdit = dynamic(() => import('../assets/svgs/casper-test.svg'));
const ApplySuccess = dynamic(() => import('../assets/svgs/apply-success.svg'));
const Approval = dynamic(() => import('../assets/svgs/approval.svg'));
const Archive = dynamic(() => import('../assets/svgs/archive.svg'));
const ArrowLeft = dynamic(() => import('../assets/svgs/arrow-left.svg'));
const ArrowRight = dynamic(() => import('../assets/svgs/arrow-right.svg'));
const Attachment = dynamic(() => import('../assets/svgs/attachment.svg'));
const Badge = dynamic(() => import('../assets/svgs/badge.svg'));
const BadgeCheck = dynamic(() => import('../assets/svgs/badge-check.svg'));
const BellWarning = dynamic(() => import('../assets/svgs/bell-warning.svg'));
const Books = dynamic(() => import('../assets/svgs/books.svg'));
const Brainstorming = dynamic(() => import('../assets/svgs/brainstorming.svg'));
const Calendar = dynamic(() => import('../assets/svgs/calendar.svg'));
const CalendarLarge = dynamic(
  () => import('../assets/svgs/calendar-large.svg'),
);
const Cancel = dynamic(() => import('../assets/svgs/cancel.svg'));
const CasperTest = dynamic(() => import('../assets/svgs/achievements.svg'));
const Check = dynamic(() => import('../assets/svgs/check.svg'));
const CheckCircle = dynamic(() => import('../assets/svgs/check-circle.svg'));
const CheckCircleFilled = dynamic(
  () => import('../assets/svgs/check-circle-filled.svg'),
);
const CheckmarkBigSquare = dynamic(
  () => import('../assets/svgs/checkmark-big-square.svg'),
);
const Chevron = dynamic(() => import('../assets/svgs/chevron.svg'));
const ChevronDouble = dynamic(
  () => import('../assets/svgs/chevron-double.svg'),
);
const Clients = dynamic(() => import('../assets/svgs/clients.svg'));
const ClipboardCheck = dynamic(
  () => import('../assets/svgs/clipboard-check.svg'),
);
const Clock = dynamic(() => import('../assets/svgs/clock.svg'));
const ClockAlt = dynamic(() => import('../assets/svgs/clock-alt.svg'));
const Copy = dynamic(() => import('../assets/svgs/copy.svg'));
const Dashboard = dynamic(() => import('../assets/svgs/dashboard.svg'));
const DealExecution = dynamic(
  () => import('../assets/svgs/deal-execution.svg'),
);
const Ding = dynamic(() => import('../assets/svgs/ding.svg'));
const DiscountCodes = dynamic(
  () => import('../assets/svgs/discount-codes.svg'),
);
const DollarSend = dynamic(() => import('../assets/svgs/dollar-send.svg'));
const DraftMessage = dynamic(() => import('../assets/svgs/draft-message.svg'));
const Draggable = dynamic(() => import('../assets/svgs/draggable.svg'));
const DueDiligence = dynamic(() => import('../assets/svgs/due-diligence.svg'));
const Earnings = dynamic(() => import('../assets/svgs/earnings.svg'));
const EarningsFull = dynamic(() => import('../assets/svgs/earnings-full.svg'));
const Edit = dynamic(() => import('../assets/svgs/edit.svg'));
const Editing = dynamic(() => import('../assets/svgs/editing.svg'));
const Email = dynamic(() => import('../assets/svgs/email.svg'));
const English = dynamic(() => import('../assets/svgs/english.svg'));
const ErrorCircle = dynamic(() => import('../assets/svgs/error-circle.svg'));
const ErrorIcon = dynamic(() => import('../assets/svgs/error.svg'));
const Essay = dynamic(() => import('../assets/svgs/essay.svg'));
const Execution = dynamic(() => import('../assets/svgs/execution.svg'));
const Expand = dynamic(() => import('../assets/svgs/expand.svg'));
const Experiences = dynamic(() => import('../assets/svgs/experiences.svg'));
const Featured = dynamic(() => import('../assets/svgs/featured.svg'));
const FinancialModeling = dynamic(
  () => import('../assets/svgs/financial-modeling.svg'),
);
const FourTimes = dynamic(() => import('../assets/svgs/four-times.svg'));
const Frameworks = dynamic(() => import('../assets/svgs/frameworks.svg'));
const Globe = dynamic(() => import('../assets/svgs/globe.svg'));
const Gmat = dynamic(() => import('../assets/svgs/GMAT.svg'));
const GraduateHat = dynamic(() => import('../assets/svgs/graduate-hat.svg'));
const Gre = dynamic(() => import('../assets/svgs/GRE.svg'));
const GroupClasses = dynamic(() => import('../assets/svgs/group-classes.svg'));
const Hamburger = dynamic(() => import('../assets/svgs/hamburger.svg'));
const Help = dynamic(() => import('../assets/svgs/help.svg'));
const Hosting = dynamic(() => import('../assets/svgs/hosting.svg'));
const Image = dynamic(() => import('../assets/svgs/image.svg'));
const Inbox = dynamic(() => import('../assets/svgs/inbox.svg'));
const InfluenceAndCollaboration = dynamic(
  () => import('../assets/svgs/influence-and-collaboration.svg'),
);
const InfoCircle = dynamic(() => import('../assets/svgs/info-circle.svg'));
const InfoFilled = dynamic(() => import('../assets/svgs/info-filled.svg'));
const IntegratedReasoning = dynamic(
  () => import('../assets/svgs/integrated-reasoning.svg'),
);
const Interview = dynamic(() => import('../assets/svgs/interview.svg'));
const Leland = dynamic(() => import('../assets/svgs/leland.svg'));
const LelandCompass = dynamic(
  () => import('../assets/svgs/leland-compass.svg'),
);
const LelandLogo = dynamic(() => import('../assets/svgs/leland-logo.svg'));
const LettersOfEvaluation = dynamic(
  () => import('../assets/svgs/letters-of-evaluation.svg'),
);
const LightBulb = dynamic(() => import('../assets/svgs/light-bulb.svg'));
const Link = dynamic(() => import('../assets/svgs/link.svg'));
const LinkedInReview = dynamic(
  () => import('../assets/svgs/linkedin-review.svg'),
);
const Loader = dynamic(() => import('../assets/svgs/loader.svg'));
const Location = dynamic(() => import('../assets/svgs/location.svg'));
const Lock = dynamic(() => import('../assets/svgs/lock.svg'));
const MailChatBubble = dynamic(
  () => import('../assets/svgs/mail-chat-bubble.svg'),
);
const Math = dynamic(() => import('../assets/svgs/math.svg'));
const MdPhDEssay = dynamic(() => import('../assets/svgs/md-phd-essay.svg'));
const MenuIcon = dynamic(() => import('../assets/svgs/menu.svg'));
const Message = dynamic(() => import('../assets/svgs/message.svg'));
const MoneyGraphBarIncrease = dynamic(
  () => import('../assets/svgs/money-graph-bar-increase.svg'),
);
const Networking = dynamic(() => import('../assets/svgs/networking.svg'));
const Notifications = dynamic(() => import('../assets/svgs/notifications.svg'));
const Offerings = dynamic(() => import('../assets/svgs/offerings.svg'));
const Onboarding = dynamic(() => import('../assets/svgs/onboarding.svg'));
const OpsTools = dynamic(() => import('../assets/svgs/ops-tools.svg'));
const QuestionIllustration = dynamic(
  () => import('../assets/svgs/question-illustration.svg'),
);
const Package = dynamic(() => import('../assets/svgs/package.svg'));
const Participants = dynamic(() => import('../assets/svgs/participants.svg'));
const Pencil = dynamic(() => import('../assets/svgs/pencil.svg'));
const Permission = dynamic(() => import('../assets/svgs/permission.svg'));
const PersonalStatement = dynamic(
  () => import('../assets/svgs/personal-statement.svg'),
);
const Plus = dynamic(() => import('../assets/svgs/plus.svg'));
const PoweredByStripe = dynamic(
  () => import('../assets/svgs/powered-by-stripe.svg'),
);
const Presentations = dynamic(() => import('../assets/svgs/presentations.svg'));
const Pricing = dynamic(() => import('../assets/svgs/pricing.svg'));
const Private = dynamic(() => import('../assets/svgs/private.svg'));
const Profile = dynamic(() => import('../assets/svgs/profile.svg'));
const PromotionStrategy = dynamic(
  () => import('../assets/svgs/promotion-strategy.svg'),
);
const Public = dynamic(() => import('../assets/svgs/public.svg'));
const QuantitativeReasoning = dynamic(
  () => import('../assets/svgs/quantitative-reasoning.svg'),
);
const Question = dynamic(() => import('../assets/svgs/question.svg'));
const Ratings = dynamic(() => import('../assets/svgs/ratings.svg'));
const Reading = dynamic(() => import('../assets/svgs/reading.svg'));
const Recommendation = dynamic(
  () => import('../assets/svgs/recommendation.svg'),
);
const ExclamationIllustration = dynamic(
  () => import('../assets/svgs/exclamation-illustration.svg'),
);
const Resume = dynamic(() => import('../assets/svgs/resume.svg'));
const ResumeReview = dynamic(() => import('../assets/svgs/resume-review.svg'));
const Review = dynamic(() => import('../assets/svgs/review.svg'));
const SalaryNegotiating = dynamic(
  () => import('../assets/svgs/salary-negotiation.svg'),
);
const ScanFace = dynamic(() => import('../assets/svgs/scan-face.svg'));
const Scholarships = dynamic(() => import('../assets/svgs/scholarship.svg'));
const School = dynamic(() => import('../assets/svgs/school.svg'));
const Science = dynamic(() => import('../assets/svgs/science.svg'));
const ScreenShare = dynamic(() => import('../assets/svgs/screen-share.svg'));
const Search = dynamic(() => import('../assets/svgs/search.svg'));
const SearchListPosition = dynamic(
  () => import('../assets/svgs/search-list-position.svg'),
);
const SecondaryReview = dynamic(
  () => import('../assets/svgs/secondary-review.svg'),
);
const Send = dynamic(() => import('../assets/svgs/send.svg'));
const Services = dynamic(() => import('../assets/svgs/services.svg'));
const Settings = dynamic(() => import('../assets/svgs/settings.svg'));
const SignOut = dynamic(() => import('../assets/svgs/sign-out.svg'));
const Slack = dynamic(() => import('../assets/svgs/slack.svg'));
const Sourcing = dynamic(() => import('../assets/svgs/sourcing.svg'));
const Sparkle = dynamic(() => import('../assets/svgs/sparkle.svg'));
const SpecializationStrategy = dynamic(
  () => import('../assets/svgs/specialization-strategy.svg'),
);
const Star = dynamic(() => import('../assets/svgs/star.svg'));
const Store = dynamic(() => import('../assets/svgs/store.svg'));
const StrategyAndVision = dynamic(
  () => import('../assets/svgs/strategy-and-vision.svg'),
);
const Success = dynamic(() => import('../assets/svgs/success.svg'));
const SuccessCircle = dynamic(
  () => import('../assets/svgs/success-circle.svg'),
);
const Switch = dynamic(() => import('../assets/svgs/switch.svg'));
const Test = dynamic(() => import('../assets/svgs/test.svg'));
const Tiers = dynamic(() => import('../assets/svgs/tiers.svg'));
const TransferAdmissions = dynamic(
  () => import('../assets/svgs/transfer-admissions.svg'),
);
const Trash = dynamic(() => import('../assets/svgs/trash-alt.svg'));
const Upload = dynamic(() => import('../assets/svgs/upload.svg'));
const User = dynamic(() => import('../assets/svgs/user.svg'));
const VerbalReasoning = dynamic(
  () => import('../assets/svgs/verbal-reasoning.svg'),
);
const Video = dynamic(() => import('../assets/svgs/video.svg'));
const VideoLarge = dynamic(() => import('../assets/svgs/video-large.svg'));
const Waitlist = dynamic(() => import('../assets/svgs/waitlist.svg'));
const WarningCircle = dynamic(
  () => import('../assets/svgs/warning-circle.svg'),
);
const WarningTriangle = dynamic(
  () => import('../assets/svgs/warning-triangle.svg'),
);
const WaveDividerLeft = dynamic(
  () => import('../assets/svgs/wave-divider-left.svg'),
);
const WaveDividerTop = dynamic(
  () => import('../assets/svgs/wave-divider-top.svg'),
);
const WorkingWithHeadhunters = dynamic(
  () => import('../assets/svgs/working-with-headhunters.svg'),
);
const Writing = dynamic(() => import('../assets/svgs/writing.svg'));
const X = dynamic(() => import('../assets/svgs/x.svg'));

export enum Svg {
  ACHIEVEMENTS = 'ACHIEVEMENTS',
  ADD = 'ADD',
  ALERT = 'ALERT',
  APPLICANTS = 'APPLICANTS',
  APPLICATION = 'APPLICATION',
  APPLICATION_EDIT = 'APPLICATION_EDIT',
  APPLY_SUCCCESS = 'APPLY_SUCCCESS',
  APPROVAL = 'APPROVAL',
  ARCHIVE = 'ARCHIVE',
  ARROW_LEFT = 'ARROW_LEFT',
  ARROW_RIGHT = 'ARROW_RIGHT',
  ATTACHMENT = 'ATTACHMENT',
  BADGE = 'BADGE',
  BADGE_CHECK = 'BADGE_CHECK',
  BELL_WARNING = 'BELL_WARNING',
  BOOKS = 'BOOKS',
  BRAINSTORMING = 'BRAINSTORMING',
  CALENDAR = 'CALENDAR',
  CALENDAR_LARGE = 'CALENDAR_LARGE',
  CANCEL = 'CANCEL',
  CASPER_TEST = 'CASPER_TEST',
  CHECK = 'CHECK',
  CHECK_CIRCLE = 'CHECK_CIRCLE',
  CHECK_CIRCLE_FILLED = 'CHECK_CIRCLE_FILLED',
  CHECKMARK_BIG_SQUARE = 'CHECKMARK_BIG_SQUARE',
  CHEVRON = 'CHEVRON',
  CHEVRON_DOUBLE = 'CHEVRON_DOUBLE',
  CLIENTS = 'CLIENTS',
  CLIPBOARD_CHECK = 'CLIPBOARD_CHECK',
  CLOCK = 'CLOCK',
  CLOCK_ALT = 'CLOCK_ALT',
  COPY = 'COPY',
  DASHBOARD = 'DASHBOARD',
  DEAL_EXECUTION = 'DEAL_EXECUTION',
  DING = 'DING',
  DISCOUNT_CODES = 'DISCOUNT_CODES',
  DOLLAR_SEND = 'DOLLAR_SEND',
  DRAFT_MESSAGE = 'DRAFT_MESSAGE',
  DRAGGABLE = 'DRAGGABLE',
  DUE_DILIGENCE = 'DUE_DILIGENCE',
  EARNINGS = 'EARNINGS',
  EARNINGS_FULL = 'EARNINGS_FULL',
  EDIT = 'EDIT',
  EDITING = 'EDITING',
  EMAIL = 'EMAIL',
  ENGLISH = 'ENGLISH',
  ERROR = 'ERROR',
  ERROR_CIRCLE = 'ERROR_CIRCLE',
  ESSAY = 'ESSAY',
  EXECUTION = 'EXECUTION',
  EXPAND = 'EXPAND',
  EXPERIENCES = 'EXPERIENCES',
  FEATURED = 'FEATURED',
  FINANCIAL_MODELING = 'FINANCIAL_MODELING',
  FOUR_TIMES = 'FOUR_TIMES',
  FRAMEWORKS = 'FRAMEWORKS',
  GLOBE = 'GLOBE',
  GMAT = 'GMAT',
  GRADUATE_HAT = 'GRADUATE_HAT',
  GRE = 'GRE',
  GROUP_CLASSES = 'GROUP_CLASSES',
  HAMBURGER = 'HAMBURGER',
  HELP = 'HELP',
  HOSTING = 'HOSTING',
  IMAGE = 'IMAGE',
  INBOX = 'INBOX',
  INFLUENCE_AND_COLLABORATION = 'INFLUENCE_AND_COLLABORATION',
  INFO_CIRCLE = 'INFO_CIRCLE',
  INFO_FILLED = 'INFO_FILLED',
  INTEGRATED_REASONING = 'INTEGRATED_REASONING',
  INTERVIEW = 'INTERVIEW',
  LELAND = 'LELAND',
  LELAND_COMPASS = 'LELAND_COMPASS',
  LELAND_LOGO = 'LELAND_LOGO',
  LETTERS_OF_EVALUATION = 'LETTERS_OF_EVALUATION',
  LIGHT_BULB = 'LIGHT_BULB',
  LINK = 'LINK',
  LINKEDIN_REVIEW = 'LINKEDIN_REVIEW',
  LOADER = 'LOADER',
  LOCATION = 'LOCATION',
  LOCK = 'LOCK',
  MAIL_CHAT_BUBBLE = 'MAIL_CHAT_BUBBLE',
  MATH = 'MATH',
  MD_PHD_ESSAY = 'MD_PHD_ESSAY',
  MENU = 'MENU',
  MESSAGE = 'MESSAGE',
  MONEY_GRAPH_BAR_INCREASE = 'MONEY_GRAPH_BAR_INCREASE',
  NEGOTIATING = 'NEGOTIATING',
  NETWORKING = 'NETWORKING',
  NOTIFICATIONS = 'NOTIFICATIONS',
  OFFERINGS = 'OFFERINGS',
  ONBOARDING = 'ONBOARDING',
  OPS_TOOLS = 'OPS_TOOLS',
  QUESTION_ILLUSTRATION = 'QUESTION_ILLUSTRATION',
  PACKAGE = 'PACKAGE',
  PARTICIPANTS = 'PARTICIPANTS',
  PENCIL = 'PENCIL',
  PERMISSON = 'PERMISSION',
  PERSONAL_STATEMENT = 'PERSONAL_STATEMENT',
  PLUS = 'PLUS',
  POWERED_BY_STRIPE = 'POWERED_BY_STRIPE',
  PRESENTATIONS = 'PRESENTATIONS',
  PRICING = 'PRICING',
  PRIVATE = 'PRIVATE',
  PROFILE = 'PROFILE',
  PROMOTION_STRATEGY = 'PROMOTION_STRATEGY',
  PUBLIC = 'PUBLIC',
  QUANTITATIVE_REASONING = 'QUANTITATIVE_REASONING',
  QUESTION = 'QUESTION',
  RATINGS = 'RATINGS',
  READING = 'READING',
  RECOMMENDATION = 'RECOMMENDATION',
  EXCLAMATION_ILLUSTRATION = 'EXCLAMATION_ILLUSTRATION',
  RESUME = 'RESUME',
  RESUME_REVIEW = 'RESUME_REVIEW',
  REVIEW = 'REVIEW',
  SALARY_NEGOTIATING = 'SALARY_NEGOTIATING',
  SCAN_FACE = 'SCAN_FACE',
  SCHOLARSHIPS = 'SCHOLARSHIPS',
  SCHOOL = 'SCHOOL',
  SCIENCE = 'SCIENCE',
  SCREEN_SHARE = 'SCREEN_SHARE',
  SEARCH = 'SEARCH',
  SEARCH_LIST_POSITION = 'SEARCH_LIST_POSITION',
  SECONDARY_REVIEW = 'SECONDARY_REVIEW',
  SEND = 'SEND',
  SERVICES = 'SERVICES',
  SETTINGS = 'SETTINGS',
  SIGN_OUT = 'SIGN_OUT',
  SLACK = 'SLACK',
  SOURCING = 'SOURCING',
  SPARKLE = 'SPARKLE',
  SPECIALIZATION_STRATEGY = 'SPECIALIZATION_STRATEGY',
  STAR = 'STAR',
  STORE = 'STORE',
  STRATEGY_AND_VISION = 'STRATEGY_AND_VISION',
  SUCCESS = 'SUCCESS',
  SUCCESS_CIRCLE = 'SUCCESS_CIRCLE',
  SWITCH = 'SWITCH',
  TEST = 'TEST',
  TIERS = 'TIERS',
  TRANSFER_ADMISSIONS = 'TRANSFER_ADMISSIONS',
  TRASH = 'TRASH',
  UPLOAD = 'UPLOAD',
  USER = 'USER',
  VERBAL_REASONING = 'VERBAL_REASONING',
  VIDEO = 'VIDEO',
  VIDEO_LARGE = 'VIDEO_LARGE',
  WAITLIST = 'WAITLIST',
  WARNING_CIRCLE = 'WARNING_CIRCLE',
  WARNING_TRIANGLE = 'WARNING_TRIANGLE',
  WAVE_DIVIDER_LEFT = 'WAVE_DIVIDER_LEFT',
  WAVE_DIVIDER_TOP = 'WAVE_DIVIDER_TOP',
  WORKING_WITH_HEADHUNTERS = 'WORKING_WITH_HEADHUNTERS',
  WRITING = 'WRITING',
  X = 'X',
}

type SvgString = {
  [key in Svg]: ComponentType;
};

const SVG_STRING: SvgString = Object.freeze({
  [Svg.ACHIEVEMENTS]: Achievements,
  [Svg.ADD]: Add,
  [Svg.ALERT]: Alert,
  [Svg.APPLICANTS]: Applicants,
  [Svg.APPLICATION_EDIT]: ApplicationEdit,
  [Svg.APPLICATION]: Application,
  [Svg.APPLY_SUCCCESS]: ApplySuccess,
  [Svg.APPROVAL]: Approval,
  [Svg.ARCHIVE]: Archive,
  [Svg.ARROW_LEFT]: ArrowLeft,
  [Svg.ARROW_RIGHT]: ArrowRight,
  [Svg.ATTACHMENT]: Attachment,
  [Svg.BADGE_CHECK]: BadgeCheck,
  [Svg.BADGE]: Badge,
  [Svg.BELL_WARNING]: BellWarning,
  [Svg.BOOKS]: Books,
  [Svg.BRAINSTORMING]: Brainstorming,
  [Svg.CALENDAR_LARGE]: CalendarLarge,
  [Svg.CALENDAR]: Calendar,
  [Svg.CANCEL]: Cancel,
  [Svg.CASPER_TEST]: CasperTest,
  [Svg.CHECK_CIRCLE]: CheckCircle,
  [Svg.CHECK_CIRCLE_FILLED]: CheckCircleFilled,
  [Svg.CHECK]: Check,
  [Svg.CHECKMARK_BIG_SQUARE]: CheckmarkBigSquare,
  [Svg.CHEVRON_DOUBLE]: ChevronDouble,
  [Svg.CHEVRON]: Chevron,
  [Svg.CLIENTS]: Clients,
  [Svg.CLIPBOARD_CHECK]: ClipboardCheck,
  [Svg.CLOCK_ALT]: ClockAlt,
  [Svg.CLOCK]: Clock,
  [Svg.COPY]: Copy,
  [Svg.DASHBOARD]: Dashboard,
  [Svg.DEAL_EXECUTION]: DealExecution,
  [Svg.DING]: Ding,
  [Svg.DISCOUNT_CODES]: DiscountCodes,
  [Svg.DOLLAR_SEND]: DollarSend,
  [Svg.DRAFT_MESSAGE]: DraftMessage,
  [Svg.DRAGGABLE]: Draggable,
  [Svg.DUE_DILIGENCE]: DueDiligence,
  [Svg.EARNINGS]: Earnings,
  [Svg.EARNINGS_FULL]: EarningsFull,
  [Svg.EDIT]: Edit,
  [Svg.EDITING]: Editing,
  [Svg.EMAIL]: Email,
  [Svg.ENGLISH]: English,
  [Svg.ERROR_CIRCLE]: ErrorCircle,
  [Svg.ERROR]: ErrorIcon,
  [Svg.ESSAY]: Essay,
  [Svg.EXECUTION]: Execution,
  [Svg.EXPAND]: Expand,
  [Svg.EXPERIENCES]: Experiences,
  [Svg.FEATURED]: Featured,
  [Svg.FINANCIAL_MODELING]: FinancialModeling,
  [Svg.FOUR_TIMES]: FourTimes,
  [Svg.FRAMEWORKS]: Frameworks,
  [Svg.GMAT]: Gmat,
  [Svg.GLOBE]: Globe,
  [Svg.GRADUATE_HAT]: GraduateHat,
  [Svg.GRE]: Gre,
  [Svg.GROUP_CLASSES]: GroupClasses,
  [Svg.HAMBURGER]: Hamburger,
  [Svg.HELP]: Help,
  [Svg.HOSTING]: Hosting,
  [Svg.IMAGE]: Image,
  [Svg.INBOX]: Inbox,
  [Svg.INFLUENCE_AND_COLLABORATION]: InfluenceAndCollaboration,
  [Svg.INFO_CIRCLE]: InfoCircle,
  [Svg.INFO_FILLED]: InfoFilled,
  [Svg.INTEGRATED_REASONING]: IntegratedReasoning,
  [Svg.INTERVIEW]: Interview,
  [Svg.LELAND_COMPASS]: LelandCompass,
  [Svg.LELAND_LOGO]: LelandLogo,
  [Svg.LELAND]: Leland,
  [Svg.LETTERS_OF_EVALUATION]: LettersOfEvaluation,
  [Svg.LIGHT_BULB]: LightBulb,
  [Svg.LINK]: Link,
  [Svg.LINKEDIN_REVIEW]: LinkedInReview,
  [Svg.LOADER]: Loader,
  [Svg.LOCATION]: Location,
  [Svg.LOCK]: Lock,
  [Svg.MAIL_CHAT_BUBBLE]: MailChatBubble,
  [Svg.MATH]: Math,
  [Svg.MD_PHD_ESSAY]: MdPhDEssay,
  [Svg.MENU]: MenuIcon,
  [Svg.MESSAGE]: Message,
  [Svg.MONEY_GRAPH_BAR_INCREASE]: MoneyGraphBarIncrease,
  [Svg.NEGOTIATING]: SalaryNegotiating,
  [Svg.NETWORKING]: Networking,
  [Svg.NOTIFICATIONS]: Notifications,
  [Svg.OFFERINGS]: Offerings,
  [Svg.ONBOARDING]: Onboarding,
  [Svg.OPS_TOOLS]: OpsTools,
  [Svg.QUESTION_ILLUSTRATION]: QuestionIllustration,
  [Svg.PACKAGE]: Package,
  [Svg.PARTICIPANTS]: Participants,
  [Svg.PENCIL]: Pencil,
  [Svg.PERMISSON]: Permission,
  [Svg.PERSONAL_STATEMENT]: PersonalStatement,
  [Svg.PLUS]: Plus,
  [Svg.POWERED_BY_STRIPE]: PoweredByStripe,
  [Svg.PRESENTATIONS]: Presentations,
  [Svg.PRICING]: Pricing,
  [Svg.PRIVATE]: Private,
  [Svg.PROFILE]: Profile,
  [Svg.PROMOTION_STRATEGY]: PromotionStrategy,
  [Svg.PUBLIC]: Public,
  [Svg.QUANTITATIVE_REASONING]: QuantitativeReasoning,
  [Svg.QUESTION]: Question,
  [Svg.RATINGS]: Ratings,
  [Svg.READING]: Reading,
  [Svg.RECOMMENDATION]: Recommendation,
  [Svg.EXCLAMATION_ILLUSTRATION]: ExclamationIllustration,
  [Svg.RESUME_REVIEW]: ResumeReview,
  [Svg.RESUME]: Resume,
  [Svg.REVIEW]: Review,
  [Svg.SALARY_NEGOTIATING]: SalaryNegotiating,
  [Svg.SCAN_FACE]: ScanFace,
  [Svg.SCHOLARSHIPS]: Scholarships,
  [Svg.SCHOOL]: School,
  [Svg.SCIENCE]: Science,
  [Svg.SCREEN_SHARE]: ScreenShare,
  [Svg.SEARCH_LIST_POSITION]: SearchListPosition,
  [Svg.SEARCH]: Search,
  [Svg.SECONDARY_REVIEW]: SecondaryReview,
  [Svg.SEND]: Send,
  [Svg.SERVICES]: Services,
  [Svg.SETTINGS]: Settings,
  [Svg.SIGN_OUT]: SignOut,
  [Svg.SLACK]: Slack,
  [Svg.SOURCING]: Sourcing,
  [Svg.SPARKLE]: Sparkle,
  [Svg.SPECIALIZATION_STRATEGY]: SpecializationStrategy,
  [Svg.STAR]: Star,
  [Svg.STORE]: Store,
  [Svg.STRATEGY_AND_VISION]: StrategyAndVision,
  [Svg.SUCCESS_CIRCLE]: SuccessCircle,
  [Svg.SUCCESS]: Success,
  [Svg.SWITCH]: Switch,
  [Svg.TEST]: Test,
  [Svg.TIERS]: Tiers,
  [Svg.TRANSFER_ADMISSIONS]: TransferAdmissions,
  [Svg.TRASH]: Trash,
  [Svg.UPLOAD]: Upload,
  [Svg.USER]: User,
  [Svg.VERBAL_REASONING]: VerbalReasoning,
  [Svg.VIDEO_LARGE]: VideoLarge,
  [Svg.VIDEO]: Video,
  [Svg.WAITLIST]: Waitlist,
  [Svg.WARNING_CIRCLE]: WarningCircle,
  [Svg.WARNING_TRIANGLE]: WarningTriangle,
  [Svg.WAVE_DIVIDER_LEFT]: WaveDividerLeft,
  [Svg.WAVE_DIVIDER_TOP]: WaveDividerTop,
  [Svg.WORKING_WITH_HEADHUNTERS]: WorkingWithHeadhunters,
  [Svg.WRITING]: Writing,
  [Svg.X]: X,
});

interface Props extends HTMLProps<HTMLElement> {
  svgType: Svg;
}

const SvgIcon: React.FC<Props> = ({ svgType, ...props }) => {
  const SVGComponent = SVG_STRING[svgType];

  if (!SVGComponent) {
    console.error('Svg type is not supported');
    return null;
  }

  return <SVGComponent {...props} />;
};

export default SvgIcon;
