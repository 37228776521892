// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SelectClientApplicantFragment = {
  __typename?: 'Applicant';
  id: string;
  user: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    pictureLink?: string | null;
  };
};

export type SelectClientCoachingRelationshipsForCurrentCoachQueryVariables =
  Types.Exact<{
    statuses?: Types.InputMaybe<
      Array<Types.CoachingRelationshipStatus> | Types.CoachingRelationshipStatus
    >;
    archivedByCoach?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
    blockedByCoach?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
    start: Types.Scalars['Int']['input'];
    count: Types.Scalars['Int']['input'];
  }>;

export type SelectClientCoachingRelationshipsForCurrentCoachQuery = {
  __typename?: 'Query';
  coachingRelationshipsForCurrentCoach: {
    __typename?: 'CoachingRelationshipBatchResponse';
    total: number;
    results: Array<{
      __typename?: 'CoachingRelationship';
      id: string;
      applicant: {
        __typename?: 'Applicant';
        id: string;
        user: {
          __typename?: 'User';
          id: string;
          firstName: string;
          lastName: string;
          pictureLink?: string | null;
        };
      };
    }>;
  };
};

export const SelectClientApplicantFragmentDoc = gql`
  fragment SelectClient_Applicant on Applicant {
    id
    user {
      id
      firstName
      lastName
      pictureLink
    }
  }
`;
export const SelectClientCoachingRelationshipsForCurrentCoachDocument = gql`
  query SelectClient_CoachingRelationshipsForCurrentCoach(
    $statuses: [CoachingRelationshipStatus!]
    $archivedByCoach: Boolean = false
    $blockedByCoach: Boolean = false
    $start: Int!
    $count: Int!
  ) {
    coachingRelationshipsForCurrentCoach(
      statuses: $statuses
      archivedByCoach: $archivedByCoach
      blockedByCoach: $blockedByCoach
      start: $start
      count: $count
    ) {
      results {
        id
        applicant {
          ...SelectClient_Applicant
        }
      }
      total
    }
  }
  ${SelectClientApplicantFragmentDoc}
`;

/**
 * __useSelectClientCoachingRelationshipsForCurrentCoachQuery__
 *
 * To run a query within a React component, call `useSelectClientCoachingRelationshipsForCurrentCoachQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectClientCoachingRelationshipsForCurrentCoachQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectClientCoachingRelationshipsForCurrentCoachQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *      archivedByCoach: // value for 'archivedByCoach'
 *      blockedByCoach: // value for 'blockedByCoach'
 *      start: // value for 'start'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useSelectClientCoachingRelationshipsForCurrentCoachQuery(
  baseOptions: Apollo.QueryHookOptions<
    SelectClientCoachingRelationshipsForCurrentCoachQuery,
    SelectClientCoachingRelationshipsForCurrentCoachQueryVariables
  > &
    (
      | {
          variables: SelectClientCoachingRelationshipsForCurrentCoachQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SelectClientCoachingRelationshipsForCurrentCoachQuery,
    SelectClientCoachingRelationshipsForCurrentCoachQueryVariables
  >(SelectClientCoachingRelationshipsForCurrentCoachDocument, options);
}
export function useSelectClientCoachingRelationshipsForCurrentCoachLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SelectClientCoachingRelationshipsForCurrentCoachQuery,
    SelectClientCoachingRelationshipsForCurrentCoachQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SelectClientCoachingRelationshipsForCurrentCoachQuery,
    SelectClientCoachingRelationshipsForCurrentCoachQueryVariables
  >(SelectClientCoachingRelationshipsForCurrentCoachDocument, options);
}
export function useSelectClientCoachingRelationshipsForCurrentCoachSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SelectClientCoachingRelationshipsForCurrentCoachQuery,
    SelectClientCoachingRelationshipsForCurrentCoachQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SelectClientCoachingRelationshipsForCurrentCoachQuery,
    SelectClientCoachingRelationshipsForCurrentCoachQueryVariables
  >(SelectClientCoachingRelationshipsForCurrentCoachDocument, options);
}
export type SelectClientCoachingRelationshipsForCurrentCoachQueryHookResult =
  ReturnType<typeof useSelectClientCoachingRelationshipsForCurrentCoachQuery>;
export type SelectClientCoachingRelationshipsForCurrentCoachLazyQueryHookResult =
  ReturnType<
    typeof useSelectClientCoachingRelationshipsForCurrentCoachLazyQuery
  >;
export type SelectClientCoachingRelationshipsForCurrentCoachSuspenseQueryHookResult =
  ReturnType<
    typeof useSelectClientCoachingRelationshipsForCurrentCoachSuspenseQuery
  >;
export type SelectClientCoachingRelationshipsForCurrentCoachQueryResult =
  Apollo.QueryResult<
    SelectClientCoachingRelationshipsForCurrentCoachQuery,
    SelectClientCoachingRelationshipsForCurrentCoachQueryVariables
  >;
