import { Tooltip } from '@leland-dev/leland-ui-library';
import React, { type ReactNode, useId } from 'react';

export interface SwitchInputProps {
  label: ReactNode;
  isChecked: boolean;
  onToggle: Possible<(newValue: boolean) => void>;
  className?: string;
  labelClassName?: string;
  switchClassName?: string;
  destructive?: boolean;
  isDisabled?: boolean;
  toolTipForDisabled?: string;
}

const SwitchInput: React.FC<SwitchInputProps> = ({
  label,
  isChecked,
  onToggle,
  className = '',
  labelClassName = '',
  switchClassName = '',
  destructive,
  isDisabled,
  toolTipForDisabled,
}) => {
  const labelKey = useId();

  const switchToggle = (
    <div
      className={`flex w-full items-center ${
        onToggle != null
          ? isDisabled
            ? 'cursor-not-allowed'
            : 'cursor-pointer'
          : ''
      } ${className}`}
      role="switch"
      aria-checked={isChecked}
      aria-labelledby={labelKey}
      tabIndex={0}
      onClick={onToggle && !isDisabled ? () => onToggle(!isChecked) : undefined}
      onKeyUp={
        onToggle && !isDisabled
          ? (e) => {
              if (e.key === ' ') {
                onToggle(!isChecked);
              }
            }
          : undefined
      }
    >
      <div
        className={`relative shrink-0 ${switchClassName} h-6 w-11 rounded-full transition-colors ${
          isChecked
            ? destructive
              ? 'bg-leland-red'
              : 'bg-leland-primary'
            : 'bg-leland-gray-stroke'
        } ${isDisabled ? 'opacity-50' : ''} ${label != null ? 'mr-2' : ''}`}
      >
        <span
          id="switch"
          className={`absolute left-0.5 top-0.5 size-5 rounded-full border-white bg-white transition-all ${
            isChecked ? 'translate-x-full' : ''
          }`}
        />
      </div>
      <span
        id={labelKey}
        className={`select-none text-base font-normal leading-snug text-leland-gray-dark ${labelClassName}`}
      >
        {label}
      </span>
    </div>
  );

  return isDisabled && toolTipForDisabled ? (
    <Tooltip content={toolTipForDisabled}>{switchToggle}</Tooltip>
  ) : (
    switchToggle
  );
};

export default SwitchInput;
