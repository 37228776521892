import { IconSparkle } from '@leland-dev/leland-ui-library';
import React, { type FC, useCallback } from 'react';

import { LEGAL_URL } from '../../utils/constants';
import { wasMutationSuccessful } from '../../utils/mutations';
import SwitchInput from '../inputs/SwitchInput';

import {
  useGenerateSessionSummaryToggleUpdateUserMutation,
  useGenerateSessionSummaryToggleUserQuery,
} from './__generated-gql-types__/GenerateSessionSummaryToggle.generated';

export const GenerateSessionSummaryToggle: FC = () => {
  const { data } = useGenerateSessionSummaryToggleUserQuery();

  const user = data?.user;

  const optOutTranscriptionValue = !!user?.optOutTranscription;

  const [updateUser] = useGenerateSessionSummaryToggleUpdateUserMutation();

  const handleOptOutToggle = useCallback(async () => {
    if (!user) {
      return;
    }

    try {
      const optOutTranscription = !optOutTranscriptionValue;
      const res = await updateUser({
        variables: {
          user: {
            optOutTranscription,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateUser: {
            ...user,
            __typename: 'User',
            optOutTranscription,
          },
        },
      });

      if (!wasMutationSuccessful(res)) {
        console.warn(res.errors);
        throw new Error('Failed to update user');
      }
    } catch (e) {
      console.warn(e);
    }
  }, [optOutTranscriptionValue, updateUser, user]);

  return (
    <div className="w-full space-y-2">
      <div className="flex items-center justify-between space-x-4">
        <div className="flex grow items-center gap-2">
          <IconSparkle className="size-4.5" />
          <div className="flex w-full items-center justify-between space-x-2 text-lg font-medium leading-6 text-leland-gray-dark">
            <h3>Generate session summaries</h3>
          </div>
        </div>
        <div>
          <SwitchInput
            label=""
            isChecked={!optOutTranscriptionValue}
            onToggle={handleOptOutToggle}
          />
        </div>
      </div>

      <p className="text-lg leading-6 text-leland-gray-light">
        Leland can generate text summaries of your sessions, making it easy to
        revisit important concepts and stay aligned on your action items. In
        order to generate session summaries, both attendees must have the
        feature enabled.
      </p>

      <p className="leading-6 text-leland-gray-extra-light">
        By enabling this feature, you agree to Leland&rsquo;s{' '}
        <a
          className="link text-leland-primary"
          href={`${LEGAL_URL}/terms`}
          target="_blank"
          rel="noopener noreferrer"
        >
          terms
        </a>{' '}
        and{' '}
        <a
          className="link text-leland-primary"
          href={`${LEGAL_URL}/privacy-policy`}
          target="_blank"
          rel="noopener noreferrer"
        >
          privacy policy
        </a>
        .
      </p>
    </div>
  );
};
