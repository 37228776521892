import { ApolloProvider } from '@apollo/client';
import { type AppProps } from 'next/app';
import React from 'react';

import { useApollo } from './apolloClient';

interface Props {
  children: React.ReactNode;
  pageProps: AppProps['pageProps'];
}

const ApolloProviderWithAuth0 = ({ children, pageProps }: Props) => {
  const apolloClient = useApollo(pageProps);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export { ApolloProviderWithAuth0 };
