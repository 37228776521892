// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import { SetDesiredPayoutCoachFragmentDoc } from '../../__generated-gql-types__/SetDesiredPayout.generated';
import { CoachOnboardingCoachFragmentDoc } from './CoachOnboardingModal.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DisplayCoachOnboardingCoachFragment = {
  __typename?: 'Coach';
  id: string;
  optInTrialSessions?: boolean | null;
  hourlyRate?: number | null;
  desiredPayout?: number | null;
  openToLowerThanDesiredPayout?: boolean | null;
};

export type DisplayCoachOnboardingUserQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type DisplayCoachOnboardingUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    coach?: {
      __typename?: 'Coach';
      id: string;
      tier: Types.SkuTier;
      desiredPayout?: number | null;
      optInTrialSessions?: boolean | null;
      hourlyRate?: number | null;
      openToLowerThanDesiredPayout?: boolean | null;
    } | null;
  } | null;
};

export const DisplayCoachOnboardingCoachFragmentDoc = gql`
  fragment DisplayCoachOnboarding_Coach on Coach {
    id
    optInTrialSessions
    ...SetDesiredPayout_Coach
  }
  ${SetDesiredPayoutCoachFragmentDoc}
`;
export const DisplayCoachOnboardingUserDocument = gql`
  query DisplayCoachOnboarding_User {
    user {
      id
      coach {
        ...CoachOnboarding_Coach
      }
    }
  }
  ${CoachOnboardingCoachFragmentDoc}
`;

/**
 * __useDisplayCoachOnboardingUserQuery__
 *
 * To run a query within a React component, call `useDisplayCoachOnboardingUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplayCoachOnboardingUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplayCoachOnboardingUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useDisplayCoachOnboardingUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DisplayCoachOnboardingUserQuery,
    DisplayCoachOnboardingUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DisplayCoachOnboardingUserQuery,
    DisplayCoachOnboardingUserQueryVariables
  >(DisplayCoachOnboardingUserDocument, options);
}
export function useDisplayCoachOnboardingUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DisplayCoachOnboardingUserQuery,
    DisplayCoachOnboardingUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DisplayCoachOnboardingUserQuery,
    DisplayCoachOnboardingUserQueryVariables
  >(DisplayCoachOnboardingUserDocument, options);
}
export function useDisplayCoachOnboardingUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DisplayCoachOnboardingUserQuery,
    DisplayCoachOnboardingUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DisplayCoachOnboardingUserQuery,
    DisplayCoachOnboardingUserQueryVariables
  >(DisplayCoachOnboardingUserDocument, options);
}
export type DisplayCoachOnboardingUserQueryHookResult = ReturnType<
  typeof useDisplayCoachOnboardingUserQuery
>;
export type DisplayCoachOnboardingUserLazyQueryHookResult = ReturnType<
  typeof useDisplayCoachOnboardingUserLazyQuery
>;
export type DisplayCoachOnboardingUserSuspenseQueryHookResult = ReturnType<
  typeof useDisplayCoachOnboardingUserSuspenseQuery
>;
export type DisplayCoachOnboardingUserQueryResult = Apollo.QueryResult<
  DisplayCoachOnboardingUserQuery,
  DisplayCoachOnboardingUserQueryVariables
>;
