export function isEmptyString(val: string | null | undefined): boolean {
  if (val == null) {
    return true;
  } else {
    return val.trim().length === 0;
  }
}

// from https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
export function uuid(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
