// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FeatureInteractionsContextUserFragment = {
  __typename?: 'User';
  id: string;
  interactedFeatures?: Array<string> | null;
};

export type FeatureInteractionsContextGetUserQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FeatureInteractionsContextGetUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    interactedFeatures?: Array<string> | null;
  } | null;
};

export type FeatureInteractionsContextFeatureInteractedQueryVariables =
  Types.Exact<{
    feature: Types.Scalars['String']['input'];
    maxInitialInteraction?: Types.Scalars['Int']['input'];
    maxPostCoolOffInteraction?: Types.Scalars['Int']['input'];
    since?: Types.InputMaybe<Types.Scalars['Long']['input']>;
  }>;

export type FeatureInteractionsContextFeatureInteractedQuery = {
  __typename?: 'Query';
  featureInteracted: boolean;
};

export type FeatureInteractionsContextLogInteractionMutationVariables =
  Types.Exact<{
    feature: Types.Scalars['String']['input'];
  }>;

export type FeatureInteractionsContextLogInteractionMutation = {
  __typename?: 'Mutation';
  logFeatureInteraction: boolean;
};

export const FeatureInteractionsContextUserFragmentDoc = gql`
  fragment FeatureInteractionsContext_User on User {
    id
    interactedFeatures
  }
`;
export const FeatureInteractionsContextGetUserDocument = gql`
  query FeatureInteractionsContext_GetUser {
    user {
      ...FeatureInteractionsContext_User
    }
  }
  ${FeatureInteractionsContextUserFragmentDoc}
`;

/**
 * __useFeatureInteractionsContextGetUserQuery__
 *
 * To run a query within a React component, call `useFeatureInteractionsContextGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureInteractionsContextGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureInteractionsContextGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureInteractionsContextGetUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FeatureInteractionsContextGetUserQuery,
    FeatureInteractionsContextGetUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FeatureInteractionsContextGetUserQuery,
    FeatureInteractionsContextGetUserQueryVariables
  >(FeatureInteractionsContextGetUserDocument, options);
}
export function useFeatureInteractionsContextGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeatureInteractionsContextGetUserQuery,
    FeatureInteractionsContextGetUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FeatureInteractionsContextGetUserQuery,
    FeatureInteractionsContextGetUserQueryVariables
  >(FeatureInteractionsContextGetUserDocument, options);
}
export function useFeatureInteractionsContextGetUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FeatureInteractionsContextGetUserQuery,
    FeatureInteractionsContextGetUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FeatureInteractionsContextGetUserQuery,
    FeatureInteractionsContextGetUserQueryVariables
  >(FeatureInteractionsContextGetUserDocument, options);
}
export type FeatureInteractionsContextGetUserQueryHookResult = ReturnType<
  typeof useFeatureInteractionsContextGetUserQuery
>;
export type FeatureInteractionsContextGetUserLazyQueryHookResult = ReturnType<
  typeof useFeatureInteractionsContextGetUserLazyQuery
>;
export type FeatureInteractionsContextGetUserSuspenseQueryHookResult =
  ReturnType<typeof useFeatureInteractionsContextGetUserSuspenseQuery>;
export type FeatureInteractionsContextGetUserQueryResult = Apollo.QueryResult<
  FeatureInteractionsContextGetUserQuery,
  FeatureInteractionsContextGetUserQueryVariables
>;
export const FeatureInteractionsContextFeatureInteractedDocument = gql`
  query FeatureInteractionsContext_FeatureInteracted(
    $feature: String!
    $maxInitialInteraction: Int! = 1
    $maxPostCoolOffInteraction: Int! = 0
    $since: Long
  ) {
    featureInteracted(
      feature: $feature
      maxInitialInteraction: $maxInitialInteraction
      maxPostCoolOffInteraction: $maxPostCoolOffInteraction
      since: $since
    )
  }
`;

/**
 * __useFeatureInteractionsContextFeatureInteractedQuery__
 *
 * To run a query within a React component, call `useFeatureInteractionsContextFeatureInteractedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureInteractionsContextFeatureInteractedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureInteractionsContextFeatureInteractedQuery({
 *   variables: {
 *      feature: // value for 'feature'
 *      maxInitialInteraction: // value for 'maxInitialInteraction'
 *      maxPostCoolOffInteraction: // value for 'maxPostCoolOffInteraction'
 *      since: // value for 'since'
 *   },
 * });
 */
export function useFeatureInteractionsContextFeatureInteractedQuery(
  baseOptions: Apollo.QueryHookOptions<
    FeatureInteractionsContextFeatureInteractedQuery,
    FeatureInteractionsContextFeatureInteractedQueryVariables
  > &
    (
      | {
          variables: FeatureInteractionsContextFeatureInteractedQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FeatureInteractionsContextFeatureInteractedQuery,
    FeatureInteractionsContextFeatureInteractedQueryVariables
  >(FeatureInteractionsContextFeatureInteractedDocument, options);
}
export function useFeatureInteractionsContextFeatureInteractedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeatureInteractionsContextFeatureInteractedQuery,
    FeatureInteractionsContextFeatureInteractedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FeatureInteractionsContextFeatureInteractedQuery,
    FeatureInteractionsContextFeatureInteractedQueryVariables
  >(FeatureInteractionsContextFeatureInteractedDocument, options);
}
export function useFeatureInteractionsContextFeatureInteractedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FeatureInteractionsContextFeatureInteractedQuery,
    FeatureInteractionsContextFeatureInteractedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FeatureInteractionsContextFeatureInteractedQuery,
    FeatureInteractionsContextFeatureInteractedQueryVariables
  >(FeatureInteractionsContextFeatureInteractedDocument, options);
}
export type FeatureInteractionsContextFeatureInteractedQueryHookResult =
  ReturnType<typeof useFeatureInteractionsContextFeatureInteractedQuery>;
export type FeatureInteractionsContextFeatureInteractedLazyQueryHookResult =
  ReturnType<typeof useFeatureInteractionsContextFeatureInteractedLazyQuery>;
export type FeatureInteractionsContextFeatureInteractedSuspenseQueryHookResult =
  ReturnType<
    typeof useFeatureInteractionsContextFeatureInteractedSuspenseQuery
  >;
export type FeatureInteractionsContextFeatureInteractedQueryResult =
  Apollo.QueryResult<
    FeatureInteractionsContextFeatureInteractedQuery,
    FeatureInteractionsContextFeatureInteractedQueryVariables
  >;
export const FeatureInteractionsContextLogInteractionDocument = gql`
  mutation FeatureInteractionsContext_LogInteraction($feature: String!) {
    logFeatureInteraction(feature: $feature)
  }
`;
export type FeatureInteractionsContextLogInteractionMutationFn =
  Apollo.MutationFunction<
    FeatureInteractionsContextLogInteractionMutation,
    FeatureInteractionsContextLogInteractionMutationVariables
  >;

/**
 * __useFeatureInteractionsContextLogInteractionMutation__
 *
 * To run a mutation, you first call `useFeatureInteractionsContextLogInteractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFeatureInteractionsContextLogInteractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [featureInteractionsContextLogInteractionMutation, { data, loading, error }] = useFeatureInteractionsContextLogInteractionMutation({
 *   variables: {
 *      feature: // value for 'feature'
 *   },
 * });
 */
export function useFeatureInteractionsContextLogInteractionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FeatureInteractionsContextLogInteractionMutation,
    FeatureInteractionsContextLogInteractionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FeatureInteractionsContextLogInteractionMutation,
    FeatureInteractionsContextLogInteractionMutationVariables
  >(FeatureInteractionsContextLogInteractionDocument, options);
}
export type FeatureInteractionsContextLogInteractionMutationHookResult =
  ReturnType<typeof useFeatureInteractionsContextLogInteractionMutation>;
export type FeatureInteractionsContextLogInteractionMutationResult =
  Apollo.MutationResult<FeatureInteractionsContextLogInteractionMutation>;
export type FeatureInteractionsContextLogInteractionMutationOptions =
  Apollo.BaseMutationOptions<
    FeatureInteractionsContextLogInteractionMutation,
    FeatureInteractionsContextLogInteractionMutationVariables
  >;
