import {
  FeatureInteraction,
  useFeatureInteractions,
} from '../../../context/FeatureInteractionsContext';
import { LexSlug, useLex } from '../../../context/LexContext';
import { useOnce } from '../../../utils/hooks/useOnce';

import { useCoachOnboardingModal } from './CoachOnboardingModal';
import { useDisplayCoachOnboardingUserQuery } from './__generated-gql-types__/DisplayCoachOnboardingModal.generated';

const DisplayCoachOnboardingModal = () => {
  const { isFeatureInteracted, logFeatureInteraction } =
    useFeatureInteractions();
  const { data: userData } = useDisplayCoachOnboardingUserQuery();

  const isOptInTrialSessions = userData?.user?.coach?.optInTrialSessions;

  const { isLexEnabled } = useLex();

  const {
    setOpen: setCoachOnboardingModalOpen,
    open: isModalOpen,
    Modal: CoachOnboardingModal,
  } = useCoachOnboardingModal();

  useOnce(
    () => setCoachOnboardingModalOpen(true),
    isLexEnabled(LexSlug.FEATURE_COACH_ONBOARDING_MODAL) &&
      isFeatureInteracted(FeatureInteraction.SESSION_SUMMARY_MODAL) &&
      !isFeatureInteracted(FeatureInteraction.COACH_ONBOARDING_MODAL),
  );

  return (
    <>
      {!isOptInTrialSessions || isModalOpen ? (
        <CoachOnboardingModal
          onClose={async () => {
            if (
              !isFeatureInteracted(FeatureInteraction.COACH_ONBOARDING_MODAL)
            ) {
              await logFeatureInteraction(
                FeatureInteraction.COACH_ONBOARDING_MODAL,
              );
            }
          }}
        />
      ) : null}
    </>
  );
};

export default DisplayCoachOnboardingModal;
