import React, {
  createContext,
  type PropsWithChildren,
  useContext,
  useMemo,
} from 'react';

import {
  type LexContextLexFragment,
  useLexContextLexListQuery,
} from './__generated-gql-types__/LexContext.generated';

// list of Lex keys to evaluate. Please actively remove the key if it's been cleaned up completely
export enum LexSlug {
  FEATURE_COACH_FREE_EVENTS = 'feature-coach-free-events',
  FEATURE_COACH_OPPORTUNITIES = 'feature-coach-opportunities',
  FEATURE_EARNING_GRAPH_WITH_PAYOUT = 'feature-earning-graph-with-payout',
  FEATURE_COACH_PACKAGES_V125 = 'feature-coach-packages-v125',
  FEATURE_APPLICANT_PLANS = 'feature-applicant-plans',
  FEATURE_PHONE_NUMBER_ONBOARDING = 'feature-phone-number-onboarding',
  FEATURE_COACH_TIER_AND_TAKEHOME = 'feature-coach-tier-and-takehome',
  FEATURE_INVOICE_CUSTOMER = 'feature-invoice-customer',
  FEATURE_OFFER_TRIAL_OPT_IN = 'feature-offer-trial-opt-in',
  FEATURE_COACH_SMART_PRICING = 'feature-coach-smart-pricing',
  FEATURE_SESSION_SUMMARIES = 'feature-session-summaries',
  FEATURE_LINKEDIN_FLOW = 'feature-applicant-free-month',
  FEATURE_PACKAGE_HOURLY_UNIFICATION = 'feature-package-hourly-unification',
  FEATURE_COACH_ONBOARDING_MODAL = 'feature-coach-onboarding-modal',
}
const LEX_SLUGS_TO_EVALUATE: LexSlug[] = Object.values(LexSlug);

const MISSING_LEX_CONTEXT_PROVIDER =
  'You forgot to wrap your app in <LexProvider>';

interface LexContext {
  isLexEnabled: (lexKey: LexSlug) => boolean;
  isLexLoading: boolean;
}

export const LexContext = createContext<LexContext>({
  get isLexEnabled(): never {
    throw new Error(MISSING_LEX_CONTEXT_PROVIDER);
  },
  get isLexLoading(): never {
    throw new Error(MISSING_LEX_CONTEXT_PROVIDER);
  },
});

// to prevent potential layout shift, initialize some fully ramped lex slugs by default
// NOTE: should only be temporary until the Lex is removed
const DEFAULT_INIT_LIST: LexContextLexFragment[] = [];

/**
 * LeX is short for Leland Experiment to enable A/B testing.
 * `useLex` to check whether the experiment/feature is ramped to the current user/guest
 */
export const useLex: () => LexContext = () =>
  useContext<LexContext>(LexContext);

const LexContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  // TODO: listen to user data change to reload rampedExperiments
  const { data, loading } = useLexContextLexListQuery({
    variables: { slugs: LEX_SLUGS_TO_EVALUATE },
    skip: LEX_SLUGS_TO_EVALUATE.length === 0,
  });

  const value: LexContext = useMemo(() => {
    const lexIsRampedMap = (data?.lexListBySlugs ?? DEFAULT_INIT_LIST).reduce<
      Record<string, boolean>
    >((map, lex) => {
      map[lex.slug] = lex.isRamped;
      return map;
    }, {});

    return {
      isLexEnabled: (slug: LexSlug) => !!lexIsRampedMap[slug],
      isLexLoading: loading,
    };
  }, [data?.lexListBySlugs, loading]);

  return <LexContext.Provider value={value}>{children}</LexContext.Provider>;
};

export default LexContextProvider;
